/* eslint-disable no-restricted-syntax */
<template>
  <div>
    <b-card name="Mouvement Navire">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Numéro navire">
            <b-form-input
              v-model="$v.shipNumber.$model"
              :formatter="formatter"
              :class="{ 'is-invalid': $v.shipNumber.$error }"
            />
            <div
              v-if="$v.shipNumber.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.shipNumber.required"
              >Le numéro est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nom du navire">
            <b-form-input
              v-model="$v.shipName.$model"
              :formatter="formatter"
              :class="{ 'is-invalid': $v.shipName.$error }"
            />
            <div
              v-if="$v.shipName.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.shipName.required"
              >Le nom du navire est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Consignataire">
            <b-form-select
              v-model="$v.shipConsignee.$model"
              :options="consigneeOptions"
              :class="{ 'is-invalid': $v.shipConsignee.$error }"
            />
            <div
              v-if="$v.shipConsignee.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.shipConsignee.required"
              >le consignataire est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nature Cargo">
            <b-form-select
              v-model="$v.natureCargo.$model"
              :options="cargoOptions"
              :class="{ 'is-invalid': $v.natureCargo.$error }"
            />
            <div
              v-if="$v.natureCargo.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.natureCargo.required"
              >La nature du cargo est requise.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Type d'opération">
            <b-form-select
              v-model="$v.typeOperation.$model"
              :options="operationOptions"
              :class="{ 'is-invalid': $v.typeOperation.$error }"
            />
            <div
              v-if="$v.typeOperation.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.typeOperation.required"
              >Le type d'opération est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Date d'arrivée">
            <b-form-input
              v-model="$v.arrivalDate.$model"
              type="date"
              :class="{ 'is-invalid': $v.arrivalDate.$error }"
            />
            <div
              v-if="$v.arrivalDate.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.arrivalDate.required"
              >La date d'arrivée est requise.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Etat"
            desciption="Etat du Mouvement Navire"
          >
            <b-form-select
              v-model="$v.state.$model"
              :options="stateList"
              :class="{ 'is-invalid': $v.state.$error }"
            />
            <div
              v-if="$v.state.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.state.required"
              >L'état est requis.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <div class="text-right">
            <b-button
              variant="light"
              :disabled="process"
              @click="reset"
            >
              Rafraichir
            </b-button>
            <b-button
              variant="success"
              class="ml-2"
              type="submit"
              :disabled="process"
              @click="addMovement"
            >
              <b-spinner
                v-if="process"
                small
              />
              <span v-show="!process">Ajouter</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      shipName: null,
      shipNumber: null,
      shipConsignee: null,
      natureCargo: null,
      typeOperation: null,
      cargoList: [],
      operationList: [],
      consigneeList: [],
      state: null,
      arrivalDate: new Date().toISOString().split('T')[0],
      stateList: [
        { value: null, text: 'Choisir un état' },
        { value: 'MOORING', text: 'MOUILLAGE' },
        { value: 'DOCKED', text: 'A QUAI' },
        { value: 'LOADING', text: 'CHARGEMENT' },
        { value: 'UNLOADING', text: 'DECHARGEMENT' },
        { value: 'DEPARTURE', text: 'DEPART' },
        { value: 'DEHALAGE', text: 'DEHALAGE' },
        { value: 'PREVISION', text: 'PREVISION' },
        { value: 'ENTRY', text: 'ENTREE' },
      ],
    }
  },
  validations: {
    shipName: {
      required,
    },
    shipNumber: {
      required,
    },
    shipConsignee: {
      required,
    },
    natureCargo: {
      required,
    },
    typeOperation: {
      required,
    },
    state: {
      required,
    },
    arrivalDate: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      token: 'token',
      success: 'shipMovementSuccess',
      error: 'shipMovementError',
      process: 'shipMovementProcess',
    }),
    cargoOptions() {
      const options = this.cargoList
      const data = [
        { value: null, text: 'Choisir la nature du cargo' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
    consigneeOptions() {
      const options = this.consigneeList
      const data = [
        { value: null, text: 'Choisir le consignataire' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
    operationOptions() {
      const options = this.operationList
      const data = [
        { value: null, text: 'Choisir le type d\'opération' },
      ]
      if (options) {
        options.map(item => {
          const current = {
            value: item.id.uid,
            text: item.name,
          }
          return data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    success(val) {
      if (val) {
        switch (val.valid) {
          case 1:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mouvement Navire',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
          default:
            this.reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mouvement Navire',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mouvement Navire',
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.getAllConginee()
        this.getAllOperations()
        this.getAllCargo()
      },
    },
  },
  methods: {
    ...mapActions(['shipMovement']),
    formatter(value) {
      return value.toUpperCase()
    },
    reset() {
      this.shipName = null
      this.shipNumber = null
      this.shipConsignee = null
      this.natureCargo = null
      this.typeOperation = null
      this.arrivalDate = new Date()
      this.state = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    addMovement() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.shipMovement({
        shipName: this.shipName,
        shipNumber: this.shipNumber,
        shipConsignee: this.shipConsignee,
        natureCargo: this.natureCargo,
        typeOperation: this.typeOperation,
        arrivalDate: this.arrivalDate,
        state: this.state,
      })
    },
    async getAllConginee() {
      this.consigneeList = await this.$http.get('/api/ship-consignee/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consignataire',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllOperations() {
      this.operationList = await this.$http.get('/api/ship-movement-operation/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Opération',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllCargo() {
      this.cargoList = await this.$http.get('/api/nature-cargo/find-all', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nature Cargo',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
  },
}
</script>
